// ** React Imports
import React, { type CSSProperties, forwardRef } from 'react'

// ** Styled Components
import { StyledButton, StyledLink } from './styles'

interface IProps {
	icon: JSX.Element
	/**
	 * Icon width in REM
	 */
	width: number
	/**
	 * Icon height in REM
	 */
	height: number
	/**
	 * If 'to' prop is provided, the icon button will act as a link
	 */
	to?: string
	onClick?: React.MouseEventHandler<HTMLButtonElement>
	disabled?: boolean
	ariaLabel: string
	className?: string
	styles?: CSSProperties
}

export const IconButton = forwardRef<
	HTMLButtonElement | HTMLAnchorElement,
	IProps
>((props, ref) => {
	// ** Variables
	const {
		to,
		icon,
		width,
		height,
		onClick,
		disabled,
		ariaLabel,
		className,
		styles,
		...commonProps
	} = props

	if (to) {
		return (
			<StyledLink
				to={to}
				ref={ref as React.ForwardedRef<HTMLAnchorElement>}
				$width={width}
				$height={height}
				aria-label={ariaLabel}
				$disabled={disabled}
				{...commonProps}
			>
				{icon}
			</StyledLink>
		)
	}

	return (
		<StyledButton
			{...props}
			ref={ref as React.ForwardedRef<HTMLButtonElement>}
			type='button'
			$width={width}
			$height={height}
			onClick={onClick}
			disabled={disabled}
			$disabled={disabled}
			aria-label={ariaLabel}
			$styles={styles}
			{...commonProps}
		>
			{icon}
		</StyledButton>
	)
})
